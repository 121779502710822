import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Timeline, Event } from "react-timeline-scribble";
import Carousel from "react-bootstrap/Carousel";
import HeroCard from "../components/HeroCard";
import FallbackImg from "../components/FallbackImg";
import SEO from "../components/SEO";
import Layout from "../layouts/index";
import stepsJson from "../data/steps.json";
import {
  handleQuoteButtonClick,
  handleMailingListButtonClick,
} from "../utils/button";
import appendQueryString from "../utils/query";
import Projects from "../pages/Projects";
import Section from "../pages/Section";
import { Card, ListGroup, ListGroupItem } from "react-bootstrap";
import CallToActionButton from "../components/CallToActionButton";

const Solution = ({ data }) => {
  const {
    title,
    path,
    SEODescription,
    heroTitle,
    heroSubtitle,
    callToActionText,
    callToActionLink,
    closingCallToActionText,
    headerImage,
    serviceType,
    testimonial,
    testimonialAuthor,
  } = data.solutionData.frontmatter;

  const { html } = data.solutionData;

  const { services } = data.services.nodes[0].frontmatter;

  const planData = services.filter(service => service.name === serviceType)[0];

  const contentElemId = `content-${heroTitle}`
    .toLowerCase()
    .replace(/\s/g, "-")
    .replace(/[^a-zA-Z\-]/g, "");

  return (
    <Layout bodyClass="page-solution">
      <SEO
        title={title}
        description={SEODescription}
        imageUrl={
          data.seoImage.frontmatter.headerImage.childImageSharp.fixed.src
        }
        path={path}
      />
      <HeroCard
        title={heroTitle}
        subtitle={heroSubtitle}
        quote={testimonial}
        quoteAuthor={testimonialAuthor}
        image={headerImage}
        actionBtnText={callToActionText}
        actionBtnUrl={`#${contentElemId}`}
        size="large"
      />

      <Projects />
      {html && (
        <Container id={contentElemId} className="content-container">
          <div className="content" dangerouslySetInnerHTML={{ __html: html }} />
        </Container>
      )}
      {planData && (
        <Section
          dark
          className="planInfo"
          bgColor="#124032"
          heading={planData.heading}
          subheading={planData.introText}
        >
          <div className="plansList">
            {planData.plans.map((plan) => (
              <Card>
                <Card.Body>
                  <Card.Title>{plan.name}</Card.Title>
                  <Card.Text>{plan.text}</Card.Text>
                  {plan.features && (
                    <ListGroup>
                      {plan.features.map((feature) => (
                        <ListGroupItem>{feature}</ListGroupItem>
                      ))}
                    </ListGroup>
                  )}
                </Card.Body>
                <Card.Body className="action-container">
                  <Card.Text className="price">Rs. {plan.price}</Card.Text>
                  <Card.Text className="unit">{plan.priceUnit}</Card.Text>
                  <CallToActionButton
                    text="Choose Plan"
                    link={plan.signUpLink}
                    btnClass="btn btn-secondary"
                  />
                </Card.Body>
              </Card>
            ))}
          </div>
          {planData.notes && (
            <ul className="notes">
              {planData.notes.map((note) => (
                <li>{note}</li>
              ))}
            </ul>
          )}
        </Section>
      )}
      {closingCallToActionText && (
        <Container className="content-container closing-cta">
          <div className="content">
            {closingCallToActionText.map((text) => (
              <p>{text}</p>
            ))}
            <CallToActionButton
              text={callToActionText}
              link={callToActionLink}
              btnClass="btn btn-secondary"
            />
          </div>
        </Container>
      )}
    </Layout>
  );
};

/* TODO it would be really nice to crop images (e.g. testimonials)
  based on settings in the JSON file
*/
export const query = graphql`
  query($id: String!) {
    services: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/plan-data.md/" } }
    ) {
      nodes {
        frontmatter {
          services {
            name
            heading
            introText
            notes
            plans {
              features
              name
              price
              priceUnit
              signUpLink
            }
          }
        }
      }
    }
    solutionData: markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heroTitle
        heroSubtitle
        testimonial
        testimonialAuthor
        callToActionText
        closingCallToActionText
        callToActionLink
        path
        serviceType
        SEODescription
        headerImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
          extension
          publicURL
        }
      }
      html
    }
    seoImage: markdownRemark(id: { eq: $id }) {
      frontmatter {
        headerImage {
          childImageSharp {
            fixed(width: 600) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
          extension
          publicURL
        }
      }
    }
  }
`;

export default Solution;
